import store from "@/store/index";
import { klemmergApi } from "@/api/klemmerg";
import productBarcodeService from "@/service/productBarcodeService";

export const DataMatrixFieldSource = {
  PRODUCT_BARCODE: "ribakood",
  PRODUCT_CODE1: "tarnijakood",
  PRODUCT_SEARCHCODE: "otsikood",
};

const klemmergService = {
  getProductCodeField: (product) => {
    return `${product.code1 || "XXX"}  |  ${product.searchCode}`;
  },
  getDataMatrixField: async (product) => {
    try {
      const barcodesApiResponse = await productBarcodeService.fetchByProductId(
        product.id
      );
      if (
        Array.isArray(barcodesApiResponse) &&
        barcodesApiResponse[barcodesApiResponse.length - 1]?.barcode &&
        barcodesApiResponse.length > 0
      ) {
        return {
          dataMatrix:
            barcodesApiResponse[barcodesApiResponse.length - 1].barcode,
          source: DataMatrixFieldSource.PRODUCT_BARCODE,
        };
      }
      if (product.code1) {
        return {
          dataMatrix: product.code1,
          source: DataMatrixFieldSource.PRODUCT_CODE1,
        };
      }
      return {
        dataMatrix: product.searchCode,
        source: DataMatrixFieldSource.PRODUCT_SEARCHCODE,
      };
    } catch (e) {
      const errorMessage = "ERROR in klemmergService getDataMatrixField" + e;
      alert(errorMessage);
      console.error(errorMessage);
    }
  },
  sendToQueue: async (product, printer, dataMatrixField) => {
    // await store.dispatch("setProductItemsLoading", true);
    try {
      const response = await klemmergApi.createJob({
        // TODO: make fields more dynamic
        printerCode: printer.code,
        designCode: "PRODUCT-PT12-01",
        count: 1,
        batch: false,
        authorName: store.state.auth.account.name,
        data: [
          {
            code: klemmergService.getProductCodeField(product),
            name: product.name,
            dataMatrix: dataMatrixField,
          },
        ],
      });
      console.log("sendToQueue response", response);
      if (response.ok) {
        const responseBody = await response.json();
        console.log("sendToQueue responseBody", responseBody);
      } else {
        const errorMessage = "ERROR in klemmergService sendToQueue" + response;
        alert(errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in klemmergService sendToQueue" + e;
      alert(errorMessage);
      console.error(errorMessage);
    }
    // await store.dispatch("setProductItemsLoading", false);
  },
  getPrinters: async () => {
    // await store.dispatch("setProductItemsLoading", true);
    await store.dispatch("setProductError", null);

    try {
      const response = await klemmergApi.getPrinters();
      console.log("getPrinters response", response);
      if (response.ok) {
        const responseBody = await response.json();
        console.log("getPrinters responseBody", responseBody);
        if (responseBody.success === true) {
          return responseBody.responseObject;
        } else {
          const errorMessage =
            "ERROR in klemmergService getPrinters" + responseBody;
          alert(errorMessage);
          console.error(errorMessage);
        }
      } else {
        const errorMessage =
          "ERROR in klemmergService getPrinters" + (await response.text());
        alert(errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in klemmergService getPrinters" + e;
      alert(errorMessage);
      console.error(errorMessage);
    }
    // await store.dispatch("setProductItemsLoading", false);
  },
  getLastUserJob: async () => {
    // await store.dispatch("setProductItemsLoading", true);
    try {
      const username = store.state.auth.account.name;
      const response = await klemmergApi.getLastUserJob(username);
      console.log("getLastUserJob response", response);
      if (response.ok) {
        const responseBody = await response.json();
        console.log("getLastUserJob responseBody", responseBody);
        if (responseBody.success === true) {
          return responseBody.responseObject;
        } else {
          const errorMessage =
            "ERROR in klemmergService getLastUserJob" + response;
          alert(errorMessage);
          console.error(errorMessage);
        }
      } else {
        const errorMessage =
          "ERROR in klemmergService getLastUserJob" + (await response.text());
        alert(errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in klemmergService getLastUserJob" + e;
      alert(errorMessage);
      console.error(errorMessage);
    }
    // await store.dispatch("setProductItemsLoading", false);
  },
};

export default klemmergService;
